<template>
  <div>
    <v-navigation-drawer
        class="non-printable d-print-none"
        app
        :width="navWidth"
        right
        temporary
        v-model="setting_dialog"
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-row>
                <v-subheader class="success--text" style="border-bottom: 1px solid var(--v-success-base); width: 100%; height: 40px; padding-top: 6px">
                  Налаштування друку
                </v-subheader>
                <v-col cols="12" md="6">
                  <v-switch hide-details
                            color="grey darken-1"
                            v-model="document_setting.duplicate_on_differance_page"
                            label="2 копії на різні сторінки"
                            class="mt-0"
                            @change="duplicateOnDifferancePageChange"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="page page-a4" style="height: auto !important; min-height: 215mm">
      <div class="document-print-form">
        <template v-if="document_setting.duplicate_on_differance_page">
          <template v-for="copy in 2">
            <div :key="`copy-${copy}`">
              <div class="document-table-form">
                <div class="ar-first-report">
                  <div class="ad-row" style="font-size: 10px">
                    <div class="ad-row-left">
                      <div class="top-title">
                        <div class="top-title-left"></div>
                        <div class="top-title-right">
                          <div>ЗАТВЕРДЖЕНО</div>
                          <div>Наказ Міністерства фінансів України</div>
                          <div>28 вересня 2015 року  № 841</div>
                          <div>(у редакції наказу Міністерства фінансів України</div>
                          <div>від 10 березня 2016 року № 350)</div>
                        </div>
                      </div>
                    </div>
                    <div class="ad-row-right"></div>
                  </div>
                  <div class="ad-row">
                    <div class="ad-row-left mt-1" style="font-size: 16px; text-align: center; font-weight: bold">
                      Звіт про використання коштів, виданих на відрядження або під звіт
                    </div>
                    <div class="ad-row-right"></div>
                  </div>
                  <div class="ad-row">
                    <div class="ad-row-left mt-0">
                      <div class="top-title">
                        <div class="top-title-left"></div>
                        <div class="top-title-right">
                          {{ `№ ${document_data.doc_number} від ${formatDate(document_data.doc_date)} року` }}
                        </div>
                      </div>
                    </div>
                    <div class="ad-row-right"></div>
                  </div>
                </div>
                <div class="ad-row">
                  <div class="ad-row-left">
                    <div class="ad-inner-row">
                      <div class="ad-inner-row-left">
                        Найменування податкового
                      </div>
                      <div class="ad-inner-row-center"></div>
                      <div class="ad-inner-row-right" style="border-right: 1px solid"></div>
                    </div>
                    <div class="ad-inner-row">
                      <div class="ad-inner-row-left">
                        <div>
                          агента {{ document_data.organization_short_name }}
                        </div>
                        <div>
                          Код за ЄДРПОУ
                        </div>
                        <div style="display: flex">
                          <div class="code-box">{{ document_data.ed_1 }}</div>
                          <div class="code-box">{{ document_data.ed_2 }}</div>
                          <div class="code-box">{{ document_data.ed_3 }}</div>
                          <div class="code-box">{{ document_data.ed_4 }}</div>
                          <div class="code-box">{{ document_data.ed_5 }}</div>
                          <div class="code-box">{{ document_data.ed_6 }}</div>
                          <div class="code-box">{{ document_data.ed_7 }}</div>
                          <div class="code-box">{{ document_data.ed_8 }}</div>
                        </div>
                        <div>
                          <div style="display: flex; border-bottom: 1px solid black; ">
                            <div style="flex: 0 0 90px">
                              Відділ {{ document_data.subdivision_name }}
                            </div>
                            <div style="flex: 1">
                              Посада {{ document_data.position_name }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="ad-inner-row-center"></div>
                      <div class="ad-inner-row-right" style="border-right: 1px solid">
                        <div>Звіт затверджено в сумі</div>
                        <div>{{ document_data.text_number }}</div>
                      </div>
                    </div>
                    <div class="ad-inner-row">
                      <div class="ad-inner-row-left" style="padding-bottom: 6px">
                        <div style="display: flex;">
                          <div style="flex: 0 0 90px">
                            Цех
                          </div>
                          <div style="flex: 1">
                            Професія
                          </div>
                        </div>
                        <div style="display: flex;">
                          П.І.Б {{ document_data.person_full_name }}
                        </div>
                        <div>
                          Податковий номер (або серія і номер паспорта*)
                        </div>
                        <div style="display: flex">
                          <div class="code-box">{{ document_data.ipn_1 }}</div>
                          <div class="code-box">{{ document_data.ipn_2 }}</div>
                          <div class="code-box">{{ document_data.ipn_3 }}</div>
                          <div class="code-box">{{ document_data.ipn_4 }}</div>
                          <div class="code-box">{{ document_data.ipn_5 }}</div>
                          <div class="code-box">{{ document_data.ipn_6 }}</div>
                          <div class="code-box">{{ document_data.ipn_7 }}</div>
                          <div class="code-box">{{ document_data.ipn_8 }}</div>
                          <div class="code-box">{{ document_data.ipn_9 }}</div>
                          <div class="code-box">{{ document_data.ipn_10 }}</div>
                        </div>
                        <div style="height: 21px"></div>
                        <div>Призначення авансу: {{ document_data.purpose || '' }}</div>
                      </div>
                      <div class="ad-inner-row-center" style="padding-bottom: 6px"></div>
                      <div class="ad-inner-row-right" style="border-right: 1px solid; padding-bottom: 6px">
                        <div style="display: flex">
                          <div style="flex: 0 0 54px;">Керівник</div>
                          <div style="flex: 1; border-bottom: 1px solid black">
                            {{ director }}
                          </div>
                        </div>
                        <div>"_____" ________________________ 20____року</div>
                        <div>
                          Звіт перевірено
                        </div>
                        <div style="height: 21px">
                          {{ document_data.document_sum | formatNumber }} грн.
                        </div>
                        <div>
                          {{ document_data.doc_date | formatDate}} року
                        </div>
                        <div style="display: flex">
                          <div style="flex: 0 0 54px;">Бухгалтер</div>
                          <div style="flex: 1; border-bottom: 1px solid black"></div>
                        </div>
                      </div>
                    </div>
                    <div class="ad-inner-row">
                      <div class="ad-inner-row-left">
                        <div class="left-table-row">
                          <div class="left-table-row-col-1" style="height: auto; padding-top: 21px">
                            Залишок попереднього авансу
                          </div>
                          <div class="left-table-row-col-2" style="height: auto; padding-left: 0">
                            <div style="border-bottom: 1px solid black; height: 18px; text-align: center; font-weight: bold">Сума (грн,коп.)</div>
                            <div style="height: 18px; text-align: center; padding-left: 4px">
                              {{ document_data.start_balance_debit | formatNumber }}
                            </div>
                          </div>
                        </div>
                        <div class="left-table-row">
                          <div class="left-table-row-col-1">
                            Перевитрата
                          </div>
                          <div class="left-table-row-col-2">
                            {{ document_data.start_balance_credit | formatNumber }}
                          </div>
                        </div>
                        <div class="left-table-row">
                          <div class="left-table-row-col-1" style="border-bottom: none">
                            Одержано (від кого, № та дата)
                          </div>
                          <div class="left-table-row-col-2">

                          </div>
                        </div>
                        <div class="left-table-row">
                          <div class="left-table-row-col-1">
                            1. {{ document_data.pay_doc_1_name }}
                          </div>
                          <div class="left-table-row-col-2">
                            {{ document_data.pay_doc_1_sum | formatNumber }}
                          </div>
                        </div>
                        <div class="left-table-row">
                          <div class="left-table-row-col-1">
                            2. {{ document_data.pay_doc_2_name }}
                          </div>
                          <div class="left-table-row-col-2">
                            {{ document_data.pay_doc_2_sum | formatNumber }}
                          </div>
                        </div>
                        <div class="left-table-row">
                          <div class="left-table-row-col-1">
                            2. {{ document_data.pay_doc_3_name }}
                          </div>
                          <div class="left-table-row-col-2">
                            {{ document_data.pay_doc_3_sum | formatNumber }}
                          </div>
                        </div>
                        <div class="left-table-row">
                          <div class="left-table-row-col-1">
                            Усього отримано
                          </div>
                          <div class="left-table-row-col-2">
                            {{ document_data.turnover_debit | formatNumber }}
                          </div>
                        </div>
                        <div class="left-table-row">
                          <div class="left-table-row-col-1">
                            Витрачено
                          </div>
                          <div class="left-table-row-col-2">
                            {{ document_data.turnover_credit | formatNumber }}
                          </div>
                        </div>
                        <div class="left-table-row">
                          <div class="left-table-row-col-1">
                            Залишок
                          </div>
                          <div class="left-table-row-col-2">
                            {{ document_data.end_balance_debit | formatNumber }}
                          </div>
                        </div>
                        <div class="left-table-row">
                          <div class="left-table-row-col-1">
                            Перевитрата
                          </div>
                          <div class="left-table-row-col-2">
                            {{ document_data.end_balance_credit | formatNumber }}
                          </div>
                        </div>
                      </div>
                      <div class="ad-inner-row-center"></div>
                      <div class="ad-inner-row-right" style="border-right: 1px solid">
                        <div class="right-table-row">
                          <div class="right-table-row-col-1">
                            Дт
                          </div>
                          <div class="right-table-row-col-2">
                            Кт
                          </div>
                          <div class="right-table-row-col-3">
                            Сума (грн,коп.)
                          </div>
                        </div>
                        <div class="right-table-row">
                          <div class="right-table-row-col-1">
                            {{ document_data?.charts?.row_1?.debit || '' }}
                          </div>
                          <div class="right-table-row-col-2">
                            {{ document_data?.charts?.row_1?.credit || '' }}
                          </div>
                          <div class="right-table-row-col-3">
                            {{ document_data?.charts?.row_1?.sum ? formatNumber(document_data?.charts?.row_1?.sum) : '' }}
                          </div>
                        </div>
                        <div class="right-table-row">
                          <div class="right-table-row-col-1">
                            {{ document_data?.charts?.row_2?.debit || '' }}
                          </div>
                          <div class="right-table-row-col-2">
                            {{ document_data?.charts?.row_2?.credit || '' }}
                          </div>
                          <div class="right-table-row-col-3">
                            {{ document_data?.charts?.row_2?.sum ? formatNumber(document_data?.charts?.row_2?.sum) : '' }}
                          </div>
                        </div>
                        <div class="right-table-row">
                          <div class="right-table-row-col-1">
                            {{ document_data?.charts?.row_3?.debit || '' }}
                          </div>
                          <div class="right-table-row-col-2">
                            {{ document_data?.charts?.row_3?.credit || '' }}
                          </div>
                          <div class="right-table-row-col-3">
                            {{ document_data?.charts?.row_3?.sum ? formatNumber(document_data?.charts?.row_3?.sum) : '' }}
                          </div>
                        </div>
                        <div class="right-table-row">
                          <div class="right-table-row-col-1">
                            {{ document_data?.charts?.row_4?.debit || '' }}
                          </div>
                          <div class="right-table-row-col-2">
                            {{ document_data?.charts?.row_4?.credit || '' }}
                          </div>
                          <div class="right-table-row-col-3">
                            {{ document_data?.charts?.row_4?.sum ? formatNumber(document_data?.charts?.row_4?.sum) : '' }}
                          </div>
                        </div>
                        <div class="right-table-row">
                          <div class="right-table-row-col-1">
                            {{ document_data?.charts?.row_5?.debit || '' }}
                          </div>
                          <div class="right-table-row-col-2">
                            {{ document_data?.charts?.row_5?.credit || '' }}
                          </div>
                          <div class="right-table-row-col-3">
                            {{ document_data?.charts?.row_5?.sum ? formatNumber(document_data?.charts?.row_5?.sum) : '' }}
                          </div>
                        </div>
                        <div class="right-table-row">
                          <div class="right-table-row-col-1">
                            {{ document_data?.charts?.row_6?.debit || '' }}
                          </div>
                          <div class="right-table-row-col-2">
                            {{ document_data?.charts?.row_6?.credit || '' }}
                          </div>
                          <div class="right-table-row-col-3">
                            {{ document_data?.charts?.row_6?.sum ? formatNumber(document_data?.charts?.row_6?.sum) : '' }}
                          </div>
                        </div>
                        <div class="right-table-row">
                          <div class="right-table-row-col-1">
                            {{ document_data?.charts?.row_7?.debit || '' }}
                          </div>
                          <div class="right-table-row-col-2">
                            {{ document_data?.charts?.row_7?.credit || '' }}
                          </div>
                          <div class="right-table-row-col-3">
                            {{ document_data?.charts?.row_7?.sum ? formatNumber(document_data?.charts?.row_7?.sum) : '' }}
                          </div>
                        </div>
                        <div class="right-table-row">
                          <div class="right-table-row-col-1">
                            {{ document_data?.charts?.row_8?.debit || '' }}
                          </div>
                          <div class="right-table-row-col-2">
                            {{ document_data?.charts?.row_8?.credit || '' }}
                          </div>
                          <div class="right-table-row-col-3">
                            {{ document_data?.charts?.row_8?.sum ? formatNumber(document_data?.charts?.row_8?.sum) : '' }}
                          </div>
                        </div>
                        <div class="right-table-row">
                          <div class="right-table-row-col-1">
                            {{ document_data?.charts?.row_9?.debit || '' }}
                          </div>
                          <div class="right-table-row-col-2">
                            {{ document_data?.charts?.row_9?.credit || '' }}
                          </div>
                          <div class="right-table-row-col-3">
                            {{ document_data?.charts?.row_9?.sum ? formatNumber(document_data?.charts?.row_9?.sum) : '' }}
                          </div>
                        </div>
                        <div class="right-table-row">
                          <div class="right-table-row-col-1">
                            {{ document_data?.charts?.row_10?.debit || '' }}
                          </div>
                          <div class="right-table-row-col-2">
                            {{ document_data?.charts?.row_10?.credit || '' }}
                          </div>
                          <div class="right-table-row-col-3">
                            {{ document_data?.charts?.row_10?.sum ? formatNumber(document_data?.charts?.row_10?.sum) : '' }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="ad-inner-row">
                      <div class="ad-inner-row-left">
                        <div style="display: flex">
                          <div style="flex: 0 0 60px">
                            Додаток
                          </div>
                          <div style="flex: 0 0 90px; border-bottom: 1px solid"></div>
                          <div style="flex: 1">документів</div>
                        </div>
                      </div>
                      <div class="ad-inner-row-center"></div>
                      <div class="ad-inner-row-right"></div>
                    </div>
                    <div>
                      <div class="pre-footer" style="display: flex">
                        <div style="flex: 0 0 128px; border-bottom: 1px solid">
                          Залишок унесений
                        </div>
                        <div style="flex: 0 0 35px">в сумі</div>
                        <div style="flex: 0 0 60px; border-bottom: 1px solid"></div>
                        <div style="flex: 0 0 26px;">
                          грн.
                        </div>
                        <div style="flex: 0 0 30px; border-bottom: 1px solid">
                        </div>
                        <div style="flex: 0 0 30px;">
                          коп.
                        </div>
                        <div style="flex: 0 0 180px; border-bottom: 1px solid">
                          за касовим ордером №
                        </div>
                        <div style="flex: 0 0 10px;">

                        </div>
                        <div style="flex: 0 0 30px; border-bottom: 1px solid">
                        </div>
                        <div style="flex: 0 0 10px;">
                          від
                        </div>
                        <div style="flex: 0 0 90px; border-bottom: 1px solid">
                        </div>
                        <div style="flex: 0 0 10px;">
                          20
                        </div>
                        <div style="flex: 0 0 30px; border-bottom: 1px solid">
                        </div>
                        <div style="flex: 0 0 30px;">
                          року
                        </div>
                      </div>
                      <div class="pre-footer" style="display: flex">
                        <div style="flex: 0 0 128px;">
                          Перевитрата видана
                        </div>
                        <div style="flex: 0 0 35px"></div>
                        <div style="flex: 0 0 60px;"></div>
                        <div style="flex: 0 0 26px;"></div>
                        <div style="flex: 0 0 30px;"></div>
                        <div style="flex: 0 0 30px;"></div>
                        <div style="flex: 0 0 180px; border-bottom: 1px solid">
                          за платіжним дорученням №
                        </div>
                        <div style="flex: 0 0 10px;">

                        </div>
                        <div style="flex: 0 0 30px; border-bottom: 1px solid">
                        </div>
                        <div style="flex: 0 0 10px;">
                          від
                        </div>
                        <div style="flex: 0 0 90px; border-bottom: 1px solid">
                        </div>
                        <div style="flex: 0 0 10px;">
                          20
                        </div>
                        <div style="flex: 0 0 30px; border-bottom: 1px solid">
                        </div>
                        <div style="flex: 0 0 30px;">
                          року
                        </div>
                      </div>
                      <div class="pre-footer" style="display: flex">
                        <div style="flex: 0 0 30px; border-bottom: 1px solid">
                        </div>
                        <div style="flex: 0 0 10px;">
                        </div>
                        <div style="flex: 0 0 110px; border-bottom: 1px solid">
                        </div>
                        <div style="flex: 0 0 10px;">
                          20
                        </div>
                        <div style="flex: 0 0 30px; border-bottom: 1px solid">
                        </div>
                        <div style="flex: 0 0 30px;">
                          року
                        </div>
                        <div style="flex: 0 0 50px;">
                        </div>
                        <div style="flex: 0 0 40px;">
                          Підпис
                        </div>
                        <div style="flex: 0 0 90px; border-bottom: 1px solid">
                        </div>
                        <div style="flex: 1">
                          Перелік документів наведено на звороті.
                        </div>
                      </div>
                      <div class="footer" style="padding-top: 8px">
                        При неповерненні суми надміру витрачених коштів протягом звітного місяця, на який припадає граничний строк повернення:
                      </div>
                      <div class="footer">
                        Сума податку _______ (грн, коп.) = неповернута сума** ________ (грн, коп.) х cтавка податку***___ : 100
                      </div>
                      <div class="footer">
                        Підпис особи, яка склала розрахунок _____ 	Дата складання розрахунку ____________ 20__ року
                      </div>
                      <div class="footer">
                        <div style="display: flex">
                          <div style="flex: 0 0 240px">
                            З розрахунком ознайомлений: підпис
                          </div>
                          <div style="flex: 0 0 240px; border-bottom: 1px solid"></div>
                          <div style="flex: 0 0 80px; text-align: right">Дата</div>
                          <div style="flex: 0 0 110px; border-bottom: 1px solid"></div>
                        </div>
                      </div>
                      <div class="pre-footer" style="padding-top: 10px">
                        * Серія та номер паспорта для фізичних осіб, які через свої релігійні переконання відмовились
                        від прийняття реєстраційного номера облікової картки платника податків та повідомили про це
                        відповідний контролюючий орган і мають відмітку у паспорті.
                      </div>
                      <div class="pre-footer">
                        ** Сума розраховується з урахуванням пункту 164.5 статті 164 розділу IV Податкового кодексу України.
                      </div>
                      <div class="pre-footer">
                        *** Ставка податку відповідно до пункту 167.1 статті 167 розділу ІV Податкового кодексу України.
                      </div>
                    </div>
                  </div>
                  <div class="ad-row-right">
                    <div style="height: 40px"></div>
                    <div class="vertical-text">
                      {{`РОЗПИСКА. Прийнятий на перевірку від ${document_data.person_short_name} авансовий
                  звіт № ${document_data.doc_number} від ${formatDate(document_data.doc_date)} року.На суму
                  ${formatNumber(document_data.document_sum)} грн.,коп
                  Документів______. Підпис______`}}
                    </div>
                  </div>
                </div>
              </div>
              <p class="document-separator">&nbsp;</p>
              <div class="document-table-form">
                <div class="second-page">
                  <table>
                    <thead>
                    <tr>
                      <th style="width: 50px">№ з/п</th>
                      <th style="width: 100px">Дата документа</th>
                      <th style="width: 310px">Кому, за що і на підставі якого документа заплачено</th>
                      <th style="width: 110px">Сума (грн,коп.)</th>
                      <th>Дебет рахунка</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, idx) in document_data.pays" :key="idx">
                      <td>{{ idx + 1 }}</td>
                      <td>{{ item.date | formatDate }}</td>
                      <td>
                        {{ item.text }}
                      </td>
                      <td>
                        {{ item.sum | formatNumber }}
                      </td>
                      <td>
                        {{ item.debit }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <template v-if="copy === 1">
                <p class="document-separator" :key="`sep-${copy}`">&nbsp;</p>
              </template>
            </div>
          </template>
        </template>
        <template v-if="!document_setting.duplicate_on_differance_page && !document_setting.duplicate_on_one_page">
          <div class="document-table-form">
            <div class="ar-first-report">
              <div class="ad-row" style="font-size: 10px">
                <div class="ad-row-left">
                  <div class="top-title">
                    <div class="top-title-left"></div>
                    <div class="top-title-right">
                      <div>ЗАТВЕРДЖЕНО</div>
                      <div>Наказ Міністерства фінансів України</div>
                      <div>28 вересня 2015 року  № 841</div>
                      <div>(у редакції наказу Міністерства фінансів України</div>
                      <div>від 10 березня 2016 року № 350)</div>
                    </div>
                  </div>
                </div>
                <div class="ad-row-right"></div>
              </div>
              <div class="ad-row">
                <div class="ad-row-left mt-1" style="font-size: 16px; text-align: center; font-weight: bold">
                  Звіт про використання коштів, виданих на відрядження або під звіт
                </div>
                <div class="ad-row-right"></div>
              </div>
              <div class="ad-row">
                <div class="ad-row-left mt-0">
                  <div class="top-title">
                    <div class="top-title-left"></div>
                    <div class="top-title-right">
                      {{ `№ ${document_data.doc_number} від ${formatDate(document_data.doc_date)} року` }}
                    </div>
                  </div>
                </div>
                <div class="ad-row-right"></div>
              </div>
            </div>
            <div class="ad-row">
              <div class="ad-row-left">
                <div class="ad-inner-row">
                  <div class="ad-inner-row-left">
                    Найменування податкового
                  </div>
                  <div class="ad-inner-row-center"></div>
                  <div class="ad-inner-row-right" style="border-right: 1px solid"></div>
                </div>
                <div class="ad-inner-row">
                  <div class="ad-inner-row-left">
                    <div>
                      агента {{ document_data.organization_short_name }}
                    </div>
                    <div>
                      Код за ЄДРПОУ
                    </div>
                    <div style="display: flex">
                      <div class="code-box">{{ document_data.ed_1 }}</div>
                      <div class="code-box">{{ document_data.ed_2 }}</div>
                      <div class="code-box">{{ document_data.ed_3 }}</div>
                      <div class="code-box">{{ document_data.ed_4 }}</div>
                      <div class="code-box">{{ document_data.ed_5 }}</div>
                      <div class="code-box">{{ document_data.ed_6 }}</div>
                      <div class="code-box">{{ document_data.ed_7 }}</div>
                      <div class="code-box">{{ document_data.ed_8 }}</div>
                    </div>
                    <div>
                      <div style="display: flex; border-bottom: 1px solid black; ">
                        <div style="flex: 0 0 90px">
                          Відділ {{ document_data.subdivision_name }}
                        </div>
                        <div style="flex: 1">
                          Посада {{ document_data.position_name }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ad-inner-row-center"></div>
                  <div class="ad-inner-row-right" style="border-right: 1px solid">
                    <div>Звіт затверджено в сумі</div>
                    <div>{{ document_data.text_number }}</div>
                  </div>
                </div>
                <div class="ad-inner-row">
                  <div class="ad-inner-row-left" style="padding-bottom: 6px">
                    <div style="display: flex;">
                      <div style="flex: 0 0 90px">
                        Цех
                      </div>
                      <div style="flex: 1">
                        Професія
                      </div>
                    </div>
                    <div style="display: flex;">
                      П.І.Б {{ document_data.person_full_name }}
                    </div>
                    <div>
                      Податковий номер (або серія і номер паспорта*)
                    </div>
                    <div style="display: flex">
                      <div class="code-box">{{ document_data.ipn_1 }}</div>
                      <div class="code-box">{{ document_data.ipn_2 }}</div>
                      <div class="code-box">{{ document_data.ipn_3 }}</div>
                      <div class="code-box">{{ document_data.ipn_4 }}</div>
                      <div class="code-box">{{ document_data.ipn_5 }}</div>
                      <div class="code-box">{{ document_data.ipn_6 }}</div>
                      <div class="code-box">{{ document_data.ipn_7 }}</div>
                      <div class="code-box">{{ document_data.ipn_8 }}</div>
                      <div class="code-box">{{ document_data.ipn_9 }}</div>
                      <div class="code-box">{{ document_data.ipn_10 }}</div>
                    </div>
                    <div style="height: 21px"></div>
                    <div>Призначення авансу: {{ document_data.purpose || '' }}</div>
                  </div>
                  <div class="ad-inner-row-center" style="padding-bottom: 6px"></div>
                  <div class="ad-inner-row-right" style="border-right: 1px solid; padding-bottom: 6px">
                    <div style="display: flex">
                      <div style="flex: 0 0 54px;">Керівник</div>
                      <div style="flex: 1; border-bottom: 1px solid black">
                        {{ director }}
                      </div>
                    </div>
                    <div>"_____" ________________________ 20____року</div>
                    <div>
                      Звіт перевірено
                    </div>
                    <div style="height: 21px">
                      {{ document_data.document_sum | formatNumber }} грн.
                    </div>
                    <div>
                      {{ document_data.doc_date | formatDate}} року
                    </div>
                    <div style="display: flex">
                      <div style="flex: 0 0 54px;">Бухгалтер</div>
                      <div style="flex: 1; border-bottom: 1px solid black"></div>
                    </div>
                  </div>
                </div>
                <div class="ad-inner-row">
                  <div class="ad-inner-row-left">
                    <div class="left-table-row">
                      <div class="left-table-row-col-1" style="height: auto; padding-top: 21px">
                        Залишок попереднього авансу
                      </div>
                      <div class="left-table-row-col-2" style="height: auto; padding-left: 0">
                        <div style="border-bottom: 1px solid black; height: 18px; text-align: center; font-weight: bold">Сума (грн,коп.)</div>
                        <div style="height: 18px; text-align: center; padding-left: 4px">
                          {{ document_data.start_balance_debit | formatNumber }}
                        </div>
                      </div>
                    </div>
                    <div class="left-table-row">
                      <div class="left-table-row-col-1">
                        Перевитрата
                      </div>
                      <div class="left-table-row-col-2">
                        {{ document_data.start_balance_credit | formatNumber }}
                      </div>
                    </div>
                    <div class="left-table-row">
                      <div class="left-table-row-col-1" style="border-bottom: none">
                        Одержано (від кого, № та дата)
                      </div>
                      <div class="left-table-row-col-2">

                      </div>
                    </div>
                    <div class="left-table-row">
                      <div class="left-table-row-col-1">
                        1. {{ document_data.pay_doc_1_name }}
                      </div>
                      <div class="left-table-row-col-2">
                        {{ document_data.pay_doc_1_sum | formatNumber }}
                      </div>
                    </div>
                    <div class="left-table-row">
                      <div class="left-table-row-col-1">
                        2. {{ document_data.pay_doc_2_name }}
                      </div>
                      <div class="left-table-row-col-2">
                        {{ document_data.pay_doc_2_sum | formatNumber }}
                      </div>
                    </div>
                    <div class="left-table-row">
                      <div class="left-table-row-col-1">
                        2. {{ document_data.pay_doc_3_name }}
                      </div>
                      <div class="left-table-row-col-2">
                        {{ document_data.pay_doc_3_sum | formatNumber }}
                      </div>
                    </div>
                    <div class="left-table-row">
                      <div class="left-table-row-col-1">
                        Усього отримано
                      </div>
                      <div class="left-table-row-col-2">
                        {{ document_data.turnover_debit | formatNumber }}
                      </div>
                    </div>
                    <div class="left-table-row">
                      <div class="left-table-row-col-1">
                        Витрачено
                      </div>
                      <div class="left-table-row-col-2">
                        {{ document_data.turnover_credit | formatNumber }}
                      </div>
                    </div>
                    <div class="left-table-row">
                      <div class="left-table-row-col-1">
                        Залишок
                      </div>
                      <div class="left-table-row-col-2">
                        {{ document_data.end_balance_debit | formatNumber }}
                      </div>
                    </div>
                    <div class="left-table-row">
                      <div class="left-table-row-col-1">
                        Перевитрата
                      </div>
                      <div class="left-table-row-col-2">
                        {{ document_data.end_balance_credit | formatNumber }}
                      </div>
                    </div>
                  </div>
                  <div class="ad-inner-row-center"></div>
                  <div class="ad-inner-row-right" style="border-right: 1px solid">
                    <div class="right-table-row">
                      <div class="right-table-row-col-1">
                        Дт
                      </div>
                      <div class="right-table-row-col-2">
                        Кт
                      </div>
                      <div class="right-table-row-col-3">
                        Сума (грн,коп.)
                      </div>
                    </div>
                    <div class="right-table-row">
                      <div class="right-table-row-col-1">
                        {{ document_data?.charts?.row_1?.debit || '' }}
                      </div>
                      <div class="right-table-row-col-2">
                        {{ document_data?.charts?.row_1?.credit || '' }}
                      </div>
                      <div class="right-table-row-col-3">
                        {{ document_data?.charts?.row_1?.sum ? formatNumber(document_data?.charts?.row_1?.sum) : '' }}
                      </div>
                    </div>
                    <div class="right-table-row">
                      <div class="right-table-row-col-1">
                        {{ document_data?.charts?.row_2?.debit || '' }}
                      </div>
                      <div class="right-table-row-col-2">
                        {{ document_data?.charts?.row_2?.credit || '' }}
                      </div>
                      <div class="right-table-row-col-3">
                        {{ document_data?.charts?.row_2?.sum ? formatNumber(document_data?.charts?.row_2?.sum) : '' }}
                      </div>
                    </div>
                    <div class="right-table-row">
                      <div class="right-table-row-col-1">
                        {{ document_data?.charts?.row_3?.debit || '' }}
                      </div>
                      <div class="right-table-row-col-2">
                        {{ document_data?.charts?.row_3?.credit || '' }}
                      </div>
                      <div class="right-table-row-col-3">
                        {{ document_data?.charts?.row_3?.sum ? formatNumber(document_data?.charts?.row_3?.sum) : '' }}
                      </div>
                    </div>
                    <div class="right-table-row">
                      <div class="right-table-row-col-1">
                        {{ document_data?.charts?.row_4?.debit || '' }}
                      </div>
                      <div class="right-table-row-col-2">
                        {{ document_data?.charts?.row_4?.credit || '' }}
                      </div>
                      <div class="right-table-row-col-3">
                        {{ document_data?.charts?.row_4?.sum ? formatNumber(document_data?.charts?.row_4?.sum) : '' }}
                      </div>
                    </div>
                    <div class="right-table-row">
                      <div class="right-table-row-col-1">
                        {{ document_data?.charts?.row_5?.debit || '' }}
                      </div>
                      <div class="right-table-row-col-2">
                        {{ document_data?.charts?.row_5?.credit || '' }}
                      </div>
                      <div class="right-table-row-col-3">
                        {{ document_data?.charts?.row_5?.sum ? formatNumber(document_data?.charts?.row_5?.sum) : '' }}
                      </div>
                    </div>
                    <div class="right-table-row">
                      <div class="right-table-row-col-1">
                        {{ document_data?.charts?.row_6?.debit || '' }}
                      </div>
                      <div class="right-table-row-col-2">
                        {{ document_data?.charts?.row_6?.credit || '' }}
                      </div>
                      <div class="right-table-row-col-3">
                        {{ document_data?.charts?.row_6?.sum ? formatNumber(document_data?.charts?.row_6?.sum) : '' }}
                      </div>
                    </div>
                    <div class="right-table-row">
                      <div class="right-table-row-col-1">
                        {{ document_data?.charts?.row_7?.debit || '' }}
                      </div>
                      <div class="right-table-row-col-2">
                        {{ document_data?.charts?.row_7?.credit || '' }}
                      </div>
                      <div class="right-table-row-col-3">
                        {{ document_data?.charts?.row_7?.sum ? formatNumber(document_data?.charts?.row_7?.sum) : '' }}
                      </div>
                    </div>
                    <div class="right-table-row">
                      <div class="right-table-row-col-1">
                        {{ document_data?.charts?.row_8?.debit || '' }}
                      </div>
                      <div class="right-table-row-col-2">
                        {{ document_data?.charts?.row_8?.credit || '' }}
                      </div>
                      <div class="right-table-row-col-3">
                        {{ document_data?.charts?.row_8?.sum ? formatNumber(document_data?.charts?.row_8?.sum) : '' }}
                      </div>
                    </div>
                    <div class="right-table-row">
                      <div class="right-table-row-col-1">
                        {{ document_data?.charts?.row_9?.debit || '' }}
                      </div>
                      <div class="right-table-row-col-2">
                        {{ document_data?.charts?.row_9?.credit || '' }}
                      </div>
                      <div class="right-table-row-col-3">
                        {{ document_data?.charts?.row_9?.sum ? formatNumber(document_data?.charts?.row_9?.sum) : '' }}
                      </div>
                    </div>
                    <div class="right-table-row">
                      <div class="right-table-row-col-1">
                        {{ document_data?.charts?.row_10?.debit || '' }}
                      </div>
                      <div class="right-table-row-col-2">
                        {{ document_data?.charts?.row_10?.credit || '' }}
                      </div>
                      <div class="right-table-row-col-3">
                        {{ document_data?.charts?.row_10?.sum ? formatNumber(document_data?.charts?.row_10?.sum) : '' }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ad-inner-row">
                  <div class="ad-inner-row-left">
                    <div style="display: flex">
                      <div style="flex: 0 0 60px">
                        Додаток
                      </div>
                      <div style="flex: 0 0 90px; border-bottom: 1px solid"></div>
                      <div style="flex: 1">документів</div>
                    </div>
                  </div>
                  <div class="ad-inner-row-center"></div>
                  <div class="ad-inner-row-right"></div>
                </div>
                <div>
                  <div class="pre-footer" style="display: flex">
                    <div style="flex: 0 0 128px; border-bottom: 1px solid">
                      Залишок унесений
                    </div>
                    <div style="flex: 0 0 35px">в сумі</div>
                    <div style="flex: 0 0 60px; border-bottom: 1px solid"></div>
                    <div style="flex: 0 0 26px;">
                      грн.
                    </div>
                    <div style="flex: 0 0 30px; border-bottom: 1px solid">
                    </div>
                    <div style="flex: 0 0 30px;">
                      коп.
                    </div>
                    <div style="flex: 0 0 180px; border-bottom: 1px solid">
                      за касовим ордером №
                    </div>
                    <div style="flex: 0 0 10px;">

                    </div>
                    <div style="flex: 0 0 30px; border-bottom: 1px solid">
                    </div>
                    <div style="flex: 0 0 10px;">
                      від
                    </div>
                    <div style="flex: 0 0 90px; border-bottom: 1px solid">
                    </div>
                    <div style="flex: 0 0 10px;">
                      20
                    </div>
                    <div style="flex: 0 0 30px; border-bottom: 1px solid">
                    </div>
                    <div style="flex: 0 0 30px;">
                      року
                    </div>
                  </div>
                  <div class="pre-footer" style="display: flex">
                    <div style="flex: 0 0 128px;">
                      Перевитрата видана
                    </div>
                    <div style="flex: 0 0 35px"></div>
                    <div style="flex: 0 0 60px;"></div>
                    <div style="flex: 0 0 26px;"></div>
                    <div style="flex: 0 0 30px;"></div>
                    <div style="flex: 0 0 30px;"></div>
                    <div style="flex: 0 0 180px; border-bottom: 1px solid">
                      за платіжним дорученням №
                    </div>
                    <div style="flex: 0 0 10px;">

                    </div>
                    <div style="flex: 0 0 30px; border-bottom: 1px solid">
                    </div>
                    <div style="flex: 0 0 10px;">
                      від
                    </div>
                    <div style="flex: 0 0 90px; border-bottom: 1px solid">
                    </div>
                    <div style="flex: 0 0 10px;">
                      20
                    </div>
                    <div style="flex: 0 0 30px; border-bottom: 1px solid">
                    </div>
                    <div style="flex: 0 0 30px;">
                      року
                    </div>
                  </div>
                  <div class="pre-footer" style="display: flex">
                    <div style="flex: 0 0 30px; border-bottom: 1px solid">
                    </div>
                    <div style="flex: 0 0 10px;">
                    </div>
                    <div style="flex: 0 0 110px; border-bottom: 1px solid">
                    </div>
                    <div style="flex: 0 0 10px;">
                      20
                    </div>
                    <div style="flex: 0 0 30px; border-bottom: 1px solid">
                    </div>
                    <div style="flex: 0 0 30px;">
                      року
                    </div>
                    <div style="flex: 0 0 50px;">
                    </div>
                    <div style="flex: 0 0 40px;">
                      Підпис
                    </div>
                    <div style="flex: 0 0 90px; border-bottom: 1px solid">
                    </div>
                    <div style="flex: 1">
                      Перелік документів наведено на звороті.
                    </div>
                  </div>
                  <div class="footer" style="padding-top: 8px">
                    При неповерненні суми надміру витрачених коштів протягом звітного місяця, на який припадає граничний строк повернення:
                  </div>
                  <div class="footer">
                    Сума податку _______ (грн, коп.) = неповернута сума** ________ (грн, коп.) х cтавка податку***___ : 100
                  </div>
                  <div class="footer">
                    Підпис особи, яка склала розрахунок _____ 	Дата складання розрахунку ____________ 20__ року
                  </div>
                  <div class="footer">
                    <div style="display: flex">
                      <div style="flex: 0 0 240px">
                        З розрахунком ознайомлений: підпис
                      </div>
                      <div style="flex: 0 0 240px; border-bottom: 1px solid"></div>
                      <div style="flex: 0 0 80px; text-align: right">Дата</div>
                      <div style="flex: 0 0 110px; border-bottom: 1px solid"></div>
                    </div>
                  </div>
                  <div class="pre-footer" style="padding-top: 10px">
                    * Серія та номер паспорта для фізичних осіб, які через свої релігійні переконання відмовились
                    від прийняття реєстраційного номера облікової картки платника податків та повідомили про це
                    відповідний контролюючий орган і мають відмітку у паспорті.
                  </div>
                  <div class="pre-footer">
                    ** Сума розраховується з урахуванням пункту 164.5 статті 164 розділу IV Податкового кодексу України.
                  </div>
                  <div class="pre-footer">
                    *** Ставка податку відповідно до пункту 167.1 статті 167 розділу ІV Податкового кодексу України.
                  </div>
                </div>
              </div>
              <div class="ad-row-right">
                <div style="height: 40px"></div>
                <div class="vertical-text">
                  {{`РОЗПИСКА. Прийнятий на перевірку від ${document_data.person_short_name} авансовий
                  звіт № ${document_data.doc_number} від ${formatDate(document_data.doc_date)} року.На суму
                  ${formatNumber(document_data.document_sum)} грн.,коп
                  Документів______. Підпис______`}}
                </div>
              </div>
            </div>
          </div>
          <p class="document-separator">&nbsp;</p>
          <div class="document-table-form">
            <div class="second-page">
              <table>
                <thead>
                  <tr>
                    <th style="width: 50px">№ з/п</th>
                    <th style="width: 100px">Дата документа</th>
                    <th style="width: 310px">Кому, за що і на підставі якого документа заплачено</th>
                    <th style="width: 110px">Сума (грн,коп.)</th>
                    <th>Дебет рахунка</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, idx) in document_data.pays" :key="idx">
                    <td>{{ idx + 1 }}</td>
                    <td>{{ item.date | formatDate }}</td>
                    <td>
                      {{ item.text }}
                    </td>
                    <td>
                      {{ item.sum | formatNumber }}
                    </td>
                    <td>
                      {{ item.debit }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {convertNumberToLetterString} from "@/utils/accounting";
import documentPrintAPI from "@/utils/axios/accounting/documents/advance_report"
import {formatDate, formatNumber} from "@/filters";
import {endOfMonth} from "@/utils/icons";

export default {
  name: "advance_report_v1",
  props: {
    organization: {
      type: Object,
      default() {
        return {}
      }
    },
    print_data: {
      type: Object,
      default() {
        return {}
      }
    },
    document_id: {
      type: Number,
      default: null
    },
    global_settings: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    },
    director() {
      return (((this.print_data.response_people || []).find(i => i.category === 'director')) || {}).short_name || ''
    }
  },
  data() {
    return {
      setting_dialog: this.global_settings,
      settings_watcher: null,
      document_setting: {
        additional_text: '',
        show_balance: true,
        show_pay: true,
        show_recalculation: true,
        balance_end: true,
        show_signature: true,
        duplicate_on_one_page: false,
        duplicate_on_differance_page: false
      },
      table: [
        {
          service_name: 'Електроенергія',
          price: 1.68,
          count: 44.68,
          sum: 70.88,
        },
        {
          service_name: 'Утримання будинку та прибуд.терит.',
          price: 2.90,
          count: 30.00,
          sum: 70.88,
        },
        {
          service_name: 'Вивіз ТПВ',
          price: 138.88,
          count: 0.1689,
          sum: 23.88,
        },
      ],
      document_data: {}
    }
  },
  methods: {
    duplicateOnOnePageChange(payload) {
      if (payload) {
        this.document_setting.duplicate_on_differance_page = false
      }
    },
    duplicateOnDifferancePageChange(payload) {
      if (payload) {
        this.document_setting.duplicate_on_one_page = false
      }
    },
    watch_settings() {
      this.settings_watcher = this.$watch(
          'global_settings',
          {
            handler(payload) {
              this.setting_dialog = payload
            }
          }
      )
    },
    onNavInput(e) {
      if (!e) {
        this.$emit('closeSettings')
      }
    },
    closeSettings() {
      this.setting_dialog = false
      this.$emit('closeSettings')
    },
    formatDate,
    formatNumber,
    getResponsePerson(table, category, field='short_name') {
      if (table === 'organization') {
        return ((this.print_data?.response_people || []).find(i => i.category === category) || {})?.[field] || ''
      } else {
        return (this.document_data?.contractor_print_data || {})?.response_persons?.[category]?.[field] || ''
      }
    },
    getBalanceTitle() {
      const end_month = endOfMonth(this.document_data.month)
      const date = this.document_setting.balance_end ? end_month : this.document_data.month
      const balance = this.document_setting.balance_end
          ? this.document_data.balance_end : this.document_data.balance_start
      let balance_title = ''
      if (balance < 0) {
        balance_title = 'Переплата станом на'
      } else {
        balance_title = 'Заборгованість станом на'
      }

      return `${balance_title} ${formatDate(date, 'DD.MM.YYYY')}`
    },
    convertNumberToString(payload) {
      return convertNumberToLetterString(payload)
    }
  },
  watch: {
    document_id: {
      immediate: true,
      handler(payload) {
        if (payload) {
          documentPrintAPI.get_document_for_print(this.document_id)
              .then(response => response.data)
              .then(data => this.document_data = data)
        } else {
          this.document_data = {}
        }
      }
    }
  },
  created() {
    this.watch_settings()
  },
  beforeDestroy() {
    if (this.settings_watcher) {
      this.settings_watcher()
    }
  }
}
</script>

<style scoped lang="scss">
.document-print-form {
  width: 100%;
  font-family: Arial, Calibri, serif;
  padding: 4px 12px;
  font-size: 12px;


  .document-table-form {
    width: 100%;
    border-collapse: collapse;
    table {
      border-collapse: collapse;
    }

    thead {
      tr {
        border: 1px solid grey;

        th {
          border: 1px solid grey;
          padding: 2px 6px;
          font-size: .8rem;
          font-weight: bold;
          line-height: 1.05rem;
        }
      }
    }

    tbody {
      tr {
        border: 1px solid grey;

        td {
          border: 1px solid grey;
          padding: 2px 6px;
          font-size: .9rem;
          line-height: .94rem;
          text-align: center;

          &:nth-child(4) {
            font-weight: bold;
          }
          &:nth-child(5) {
            font-weight: bold;
          }
        }
      }

      .without-border {
        border: none !important;

        td {
          border: none !important;
        }
      }

      .total {
        border: none !important;

        td {
          font-family: "Roboto", sans-serif;
          font-weight: bold !important;
          font-size: .83rem;
          border: none !important;
        }
      }
    }
  }

  .ad-row {
    display: flex;
    width: 100%;

    .ad-row-left {
      flex: 0 0 94%;
    }
    .ad-row-right {
      flex: 1;
    }
  }
  .ad-inner-row {
    display: flex;
    .ad-inner-row-left {
      flex: 0 0 54%;
      &>div {
        padding-top: 5px;
      }
    }
    .ad-inner-row-center {
      flex: 0 0 36px;
      & > div {
        padding-top: 5px;
      }
    }
    .ad-inner-row-right {
      flex: 1;
      & > div {
        padding-top: 5px;
      }
    }
  }
  .top-title {
    display: flex;
    text-align: left;
    .top-title-left {
      flex: 0 0 60%;
    }
    .top-title-right {
      flex: 1;
    }
  }

  .code-box {
    flex: 0 0 32px;
    border-top: 1px solid #111111;
    border-bottom: 1px solid #111111;
    border-left: 1px solid #111111;
    text-align: center;
    min-height: 20px;
    &:last-child {
      border-right: 1px solid #111111;
    }
  }

  .left-table-row {
    display: flex;
    min-height: 21px;
    line-height: 21px;
    div {
      min-height: 21px;
      height: 21px;
    }
    padding-top: 0 !important;

    &:nth-child(1) {
      .left-table-row-col-1, .left-table-row-col-2 {
        border-top: 1px solid black;
        margin-top: 0 !important;
      }
    }

    .left-table-row-col-1 {
      flex: 0 0 70%;
      border-left: 1px solid black;
      border-bottom: 1px solid black;
      padding-left: 4px;
    }
    .left-table-row-col-2 {
      flex: 1%;
      border-left: 1px solid black;
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      padding-left: 4px;
      text-align: center;
    }
  }
  .right-table-row {
    display: flex;
    min-height: 21px;
    line-height: 21px;
    padding-top: 0 !important;
    font-size: 13px;
    text-align: center;
    div {
      min-height: 21px;
      height: 21px;
    }

    &:nth-child(1) {
      .right-table-row-col-1, .right-table-row-col-2, .right-table-row-col-3 {
        margin-top: 0 !important;
        border-top: 1px solid black;
        font-weight: bold;
        font-size: 12px;
        text-align: center !important;
      }
    }

    .right-table-row-col-1 {
      flex: 0 0 25%;
      border-left: 1px solid black;
      border-bottom: 1px solid black;
      font-weight: bold;
    }
    .right-table-row-col-2 {
      flex: 0 0 25%;
      border-left: 1px solid black;
      border-bottom: 1px solid black;
      font-weight: bold;
    }
    .right-table-row-col-3 {
      flex: 1%;
      border-left: 1px solid black;
      border-bottom: 1px solid black;
      font-weight: bold;
      text-align: right;
      padding-right: 6px;
    }
  }
  .pre-footer {
    padding-top: 6px;
    line-height: 14px;
  }
  .footer {
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
  }
  .vertical-text {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    padding-right: 12px;
    height: 400px;
    text-align: center;
    line-height: 16px;
    font-size: 12px;
  }
  .second-page {
    padding-left: 38px;
    padding-right: 38px;
  }

}
</style>